import React from 'react';
import {graphql, useStaticQuery} from 'gatsby';
import {Button, Row, Col, Container} from 'react-bootstrap';
import Img from 'gatsby-image';

import Layout from '../../components/layout';
import SEO from '../../components/seo';
import Section from '../../components/section';
import DomainHero from '../../components/domain-hero';
import StudyCard from '../../components/card-study';
import CardAppPlatform from '../../components/card-app-platform';
import CardAppVizComm from '../../components/card-app-vizcomm';

const NonCancerousDiseases = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: {eq: "icon_studies-navy.png"}) {
        id
        childImageSharp {
          fixed(width: 75) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
    }
  `);
  return (
    <Layout>
      <SEO title="Non-Cancerous Diseases" />
      <div className="non-cancerous-diseases-domain">
        <DomainHero
          fileName="weiss.png"
          caption="An expansive genomic resource."
          label="Mitch Weiss, M.D., Ph.D."
        />
        <div>
          <Section className="desc-section">
            <Row>
              <Col>
                <h1>Non-Cancerous Diseases</h1>
                <p className="fw-bold">Welcome to the Non-Cancerous Diseases Research Domain</p>
                <p>
                  On this page, we have gathered all you need to know about the data and analysis
                  tools we offer for non-cancerous diseases on St. Jude Cloud. We currently feature
                  a Sickle Cell Genome Project, but we are working to expand our repository to
                  include more. We also address other age-related diseases such as Alzheimer&apos;s
                  (NetAD) which is a new featured project.
                </p>
                <p>
                  Use this page for direct access to all current and future non-cancerous diseases,
                  or discover the possibilities of data curation with our list of recommended apps
                  for working with non-cancerous disease data. Get updates and important links on
                  this page to know what St. Jude Cloud is up to next!
                </p>
              </Col>
            </Row>
          </Section>

          <Container fluid className="featured__container">
            <Container fluid className="featured__icon__container">
              <Row className="featured__icon__row">
                <Col md={12} className="featured__icon__col">
                  <Img fixed={data.file.childImageSharp.fixed} className="featured__icon" />
                </Col>
              </Row>
            </Container>
          </Container>

          <Section gray>
            <Row>
              <Col md={{span: 11, offset: 1}} className="featured__icon__col--responsive">
                <Img fixed={data.file.childImageSharp.fixed} className="featured__icon" />
              </Col>
              <Col md={{span: 11, offset: 1}}>
                <strong>FEATURED STUDY</strong>
                <h4 className="featured__header">NetAD</h4>
                <p>
                  Alzheimer&apos;s Disease (AD) is the leading cause of dementia, affecting more
                  than 5 million people in the US. Characterization of AD brains by proteomics,
                  transcriptomics, and genomics technologies has played vital roles and laid the
                  foundation in dissecting molecular bases of AD. To increase understanding of this
                  incurable disease, we have assembled a diverse team for this long-term project to
                  utilize a network modeling solution (NetAD) which has exposed &quot;hidden&quot;
                  drivers that play a key role in this mechanism.
                </p>
                <Button href="/studies/netad" variant="non-cancerous-diseases">
                  Learn More
                </Button>
              </Col>
            </Row>
          </Section>
          <Section>
            <Row>
              <Col xs={12}>
                <h2>Apps</h2>
                <p>
                  St. Jude Cloud is comprised of multiple applications that allow you to interact
                  with our data in different ways.
                </p>
              </Col>
            </Row>
            <Row>
              <Col />
              <Col xs="auto">
                <Row>
                  <CardAppPlatform
                    lg={6}
                    url="https://platform.stjude.cloud/data/cohorts/non-cancerous-diseases"
                  />
                  <CardAppVizComm
                    lg={6}
                    url="https://viz.stjude.cloud/visualizations?research-domain=non-cancerous-diseases"
                  />
                </Row>
              </Col>
              <Col />
            </Row>
          </Section>

          <Section gray>
            <Row className="mb-3">
              <Col xs={12}>
                <h2>Study</h2>
                <p>
                  We offer data from several studies, include the Sickle Cell Genome Project. Learn
                  about the whole-genome sequencing data provided to academic researchers through
                  St. Jude Cloud.
                </p>
              </Col>
            </Row>
            <Row className="studies__list">
              <StudyCard
                title="Sickle Cell Genome Project"
                url="https://sickle-cell.stjude.cloud/"
                external
              />
              <StudyCard title="NetAD" url="/studies/netad" />
            </Row>
          </Section>
        </div>
      </div>
    </Layout>
  );
};

export default NonCancerousDiseases;
